@import '../../styles/variables';

.Section {
  background-color: var(--color-white);

  /* Theme variations */
  &.theme-1 {
    background-color: var(--color-1);
  }

  &.theme-2 {
    background-color: var(--color-2);
  }

  &.theme-3 {
    background-color: var(--color-3);
  }

  &.theme-4 {
    background-color: var(--color-4);
  }

  &.theme-5 {
    background-color: var(--color-5);
  }

  & > div {
    display: grid;
    margin: 0 auto;
    max-width: var(--max-width-page);
    padding: var(--spacing-4) var(--spacing-2);
    gap: var(--spacing-4);
    container: content / inline-size;

    & > h2 {
      text-align: center;
    }
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Section > div {
    padding: var(--spacing-4) var(--spacing-3);
  }
}

@media only screen and (width >= $tablet-landscape) {
  .Section > div {
    padding: var(--spacing-5) var(--spacing-3);
    gap: var(--spacing-5);
  }
}

@media only screen and (width >= $max-width-page) {
  .Section > div {
    padding: var(--spacing-7) var(--spacing-4);
    gap: var(--spacing-7);
  }
}
