.AvatarUpload {
  --size: 14rem;

  display: block;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  margin: 0 auto;
  width: var(--size);
  height: var(--size);
  box-sizing: border-box;
  aspect-ratio: 1 / 1;
  background-color: var(--color-4);

  label {
    position: absolute;
    inset: 0;
    background-color: antiquewhite;
    opacity: 0;
    cursor: pointer;
  }

  .Icon {
    position: absolute;
    inset: 0;
    background-color: var(--color-1);
    mask-image: url(/svg/avatar/avatar_1.svg);
    mask-repeat: no-repeat;
    mask-position: center 42%;
    mask-size: 45%;
  }

  .Image {
    position: absolute;
    inset: 0;
    background-size: cover;
    border-radius: 50%;
  }

  button {
    position: absolute;
    right: -0.8rem;
    bottom: 0;
  }
}
