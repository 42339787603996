.Divider {
  text-align: center;
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
  gap: var(--spacing-3);

  span:first-child,
  span:last-child {
    display: block;
    height: 2px;
    width: 100%;
    background-color: #ececec;
    margin-top: 2px;
  }
}
