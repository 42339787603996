.IconAvatar {
  --size: 6.4rem;
  --background: var(--color-4);
  --border: var(--color-white);
  --default-icon-size: 12rem;

  display: block;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;

  box-sizing: border-box;
  aspect-ratio: 1 / 1;
  background-color: var(--background);
  border: 1px solid var(--border);

  &.palette-1 {
    --background: var(--palette-1);
  }
  &.palette-2 {
    --background: var(--palette-2);
  }
  &.palette-3 {
    --background: var(--palette-3);
  }
  &.palette-4 {
    --background: var(--palette-4);
  }
  &.palette-5 {
    --background: var(--palette-5);
  }
  &.palette-6 {
    --background: var(--palette-6);
  }
  &.palette-7 {
    --background: var(--palette-7);
  }
  &.palette-8 {
    --background: var(--palette-8);
  }

  span {
    position: absolute;
    inset: 0;
    background-color: var(--color-1);
    mask-image: url(/svg/avatar/avatar_1.svg);
    mask-repeat: no-repeat;
    mask-position: center 42%;
    mask-size: 60%;
  }

  &.selected {
    --border: var(--color-1);
  }

  &.selected:not(.palette) {
    --background: var(--color-1);

    span {
      background-color: var(--color-white);
    }
  }

  &.palette {
    span {
      background-color: var(--color-white);
    }
  }

  &.size-fixed {
    --size: var(--default-icon-size);

    height: var(--size);
    width: var(--size);
  }

  &.size-fluid-container {
    --size: var(--default-icon-size);

    height: var(--size);
    width: var(--size);

    @supports (font-size: 1cqw) {
      --size: clamp(6rem, 44cqw, var(--default-icon-size));
    }

    span {
      mask-size: 45%;
    }
  }

  &.size-fluid-height,
  &.size-fluid-width {
    max-width: var(--default-icon-size);
    max-height: var(--default-icon-size);
    margin: 0 auto;

    span {
      mask-size: 45%;
    }
  }

  &.size-fluid-height {
    height: 100%;
  }

  &.size-fluid-width {
    width: 100%;
  }
}
