.Card {
  background-color: var(--color-4);
  border-radius: 1rem;
  color: var(--color-black);
  text-decoration: none;
  border: 1px solid var(--color-4);

  h3 {
    font-size: 1.8rem;
  }

  &.desaturate {
    filter: grayscale(100%);
    background-color: #ddd;
    opacity: 0.6;
  }

  &.border-1 {
    border-color: var(--color-1);
  }

  &.border-2 {
    border-color: var(--color-2);
  }

  &.border-3 {
    border-color: var(--color-3);
  }

  &.border-4 {
    border-color: var(--color-4);
  }

  &.border-5 {
    border-color: var(--color-5);
  }
}

:where(.Card) {
  padding: var(--spacing-2);
}
