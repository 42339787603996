.Wizard {
  display: flex;
  gap: var(--spacing-2);
  justify-content: center;

  button {
    background-color: var(--color-4);
    border: 1px solid var(--color-1);
    color: var(--color-1);
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 3.2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.4rem;

    &[disabled] {
      pointer-events: none;
      background-color: #fff;
    }

    &.is-done {
      border: 0;

      &[disabled] {
        background-color: var(--color-4);
      }
    }

    &[aria-pressed='true'] {
      background-color: var(--color-1);
      color: var(--color-white);
    }
  }
}

.View {
  &[hidden] {
    display: none;
  }
}
